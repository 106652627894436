import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useUserSettingsStore = defineStore(
  'user-settings',
  () => {
    const userSettings = ref({
      announcements: {
        lastReadAnnouncementDate: null,
        hasReadLatestAnnouncement: false
      },
      tours: []
    })

    // Announcements related code
    const markAnnouncementAsRead = (lastAnnouncementUpdatedDate) => {
      userSettings.value.announcements.hasReadLatestAnnouncement = true
      userSettings.value.announcements.lastReadAnnouncementDate = lastAnnouncementUpdatedDate
    }

    const isLatestAnnouncementRead = (announcementDate) => {
      if (
        userSettings.value.announcements.lastReadAnnouncementDate &&
        announcementDate == userSettings.value.announcements.lastReadAnnouncementDate
      ) {
        return true
      } else {
        return false
      }
    }

    const validateAnnouncementDate = (announcementDate) => {
      if (!isLatestAnnouncementRead(announcementDate)) {
        userSettings.value.announcements.hasReadLatestAnnouncement = false
      }
    }

    const hasNewAnnouncements = computed(() => {
      return !userSettings.value.announcements.hasReadLatestAnnouncement
    })

    // Tours related code
    const markTourAsCompleted = (tourName) => {
      const tourIndex = userSettings.value.tours.findIndex((tour) => tour[tourName])
      if (tourIndex !== -1) {
        userSettings.value.tours[tourIndex][tourName].completed = true
      } else {
        userSettings.value.tours.push({ [tourName]: { completed: true } })
      }
    }

    const isTourCompleted = (tourName) => {
      return userSettings.value.tours.some((tour) => tour[tourName]?.completed)
    }

    const resetAllTours = () => {
      userSettings.value.tours = []
    }

    return {
      userSettings,
      hasNewAnnouncements,
      validateAnnouncementDate,
      markAnnouncementAsRead,
      markTourAsCompleted,
      isTourCompleted,
      resetAllTours
    }
  },
  {
    persist: true
  }
)
