import { createRouter, createWebHistory } from 'vue-router'
import { useGlobalSettingsStore } from '@/stores/global-settings'
import { useUserSettingsStore } from '@/stores/user-settings-store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'Arcane Hunter Home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: 'hunter',
        name: 'Arcane Hunter',
        component: () => import('../views/ProfileView.vue'),
        children: [
          {
            path: 'edit-details',
            name: 'Arcane Hunter Edit Details',
            component: () => import('../components/social-icons-section.vue')
          }
        ]
      },
      {
        path: 'creatures',
        name: 'Arcane Hunter Creatures',
        component: () => import('../views/CreaturesView.vue')
      },
      {
        path: 'settings',
        name: 'Arcane Hunter Settings',
        component: () => import('../views/SettingsView.vue')
      },
      {
        path: 'community',
        name: 'Arcane Hunter Community',
        component: () => import('../views/CommunityView.vue')
      },
      {
        path: 'transaction-status',
        name: 'Transaction Status',
        component: () => import('../components/payments/transaction-status.vue')
      },
      {
        path: 'privacy-policy',
        name: 'Privacy Policy',
        component: () => import('../components/pages/page-component.vue'),
        props: { pageSlugId: 'privacy-policy' }
      },
      {
        path: 'terms-and-conditions',
        name: 'Terms and Conditions',
        component: () => import('../components/pages/page-component.vue'),
        props: { pageSlugId: 'terms-and-conditions' }
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: () => import('../components/pages/page-component.vue'),
        // component: () => import('../components/pages/faq-page.vue'),
        children: [
          {
            path: ':pageSlugId',
            name: 'FAQDetail',
            component: () => import('../components/pages/page-component.vue'),
            props: (route) => ({ pageSlugId: route.params.pageSlugId, pageSection: 'faq' })
          }
        ]
      },
      {
        path: '/guide',
        name: 'Guide',
        component: () => import('../components/pages/page-section-component.vue'),
        props: (route) => ({ pageSection: 'guide' })
        // component: () => import('../components/pages/guide-page.vue'),
      },
      {
        path: '/guide/:pageSlugId',
        name: 'GuideDetail',
        component: () => import('../components/pages/page-component.vue'),
        props: (route) => ({ pageSlugId: route.params.pageSlugId, pageSection: 'guide' })
      },
      {
        path: '/leaderboard',
        name: 'Leaderboard',
        component: () => import('../components/leader-board.vue')
      }
    ]
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/MaintenancePageView.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/Page.vue'),
  //   children: [
  //     {
  //       path: ':pathMatch(.*)*',
  //       name: 'Nested About Pages',
  //       component: () => import('../views/Page.vue')
  //     }
  //   ]
  // },
  // Login is being handled by the backend now so not needing this route
  // Keeping it here for reference and in case needing to test auth0 frotnend spa login
  {
    path: '/login/callback',
    name: 'Callback',
    component: () => import('../views/Callback.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    component: () => import('../views/ErrorPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const offset = to.name === 'GuideDetail' ? 170 : 106 // Apply offset only for GuideDetail route
      const element = document.querySelector(to.hash)
      if (element) {
        setTimeout(() => {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - offset
          window.scrollTo({ top: elementPosition, behavior: 'smooth' })
        }, 0)
        return { el: to.hash, behavior: 'smooth' }
      }
    }
    return { top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const globalSettings = useGlobalSettingsStore()
  const userSettings = useUserSettingsStore()

  // Check if navigating to a hash on the current page
  if (to.path === from.path && to.hash) {
    next()
    return
  }

  // Check if the target route is the maintenance page
  if (to.path === '/maintenance') {
    next()
    return
  }

  try {
    await globalSettings.getSiteSettings()
    userSettings.validateAnnouncementDate(
      globalSettings.apiSiteSettings.lastUpdatedNewsAnnouncement
    )
    if (!globalSettings.hasUrlBeenReplaced) {
      globalSettings.toggleLoading(true)
    } else {
      globalSettings.toggleHasUrlBeenReplaced(false)
    }

    if (globalSettings?.apiSiteSettings?.isMaintenanceMode) {
      next({ path: '/maintenance' })
      return
    }

    // The loading spinner keeps spinning on pages that are just thepageSlugId
    // so adding this check and a timer as backup to make sure the loading spinner turns off
    // TODO: i don't like doing this, it needs to be implemented in a better way
    if (to.matched.some((record) => record.props.default?.pageSlugId)) {
      setTimeout(() => {
        globalSettings.toggleLoading(false)
      }, 2000)
    }
    next()
  } catch (error) {
    console.error('Failed to get site settings:', error)
    next({ path: '/maintenance' }) // Optionally, you can prevent navigation if the request fails
  }
})

export default router
