import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fetchSettings } from '@/api/settingsApi'
import { logSentryError } from '@/utils/tracking/sentry'

export const useGlobalSettingsStore = defineStore('globalSettings', () => {
  const isLoading = ref(false)
  const apiSiteSettings = ref(null)
  const hasUrlBeenReplaced = ref(false)
  const displayAlertMessage = ref(null)
  const displayCurrentSeasonBlock = ref(false)
  const currentSeason = ref(null)
  const tours = ref([])
  const tourQueue = ref([])
  const isTourRunning = ref(false)

  const isDeveloperModeFeatures = ref(import.meta.env.VITE_USE_DEVELOPER_FEATURES == 'true')

  const shouldUseDummyData = ref(import.meta.env.VITE_USE_DUMMY_DATA == 'true')

  const toggleLoading = (loadingState) => {
    isLoading.value = loadingState
  }

  const getSiteSettings = async () => {
    try {
      apiSiteSettings.value = await fetchSettings(import.meta.env.VITE_API_BASE_DOMAIN)
      if (displayAlertMessage.value === null || displayAlertMessage.value === undefined) {
        displayAlertMessage.value = apiSiteSettings.value?.shouldDisplayAlertMessage
      }
      displayCurrentSeasonBlock.value = apiSiteSettings.value?.shouldDisplayCurrentSeasonBlock
      currentSeason.value = apiSiteSettings.value?.currentSeason
      tours.value = apiSiteSettings.value?.tourGroups
    } catch (error) {
      logSentryError(error)
      console.error(error)
      throw error
    }
  }

  const toggleHasUrlBeenReplaced = (hasBeenReplaced) => {
    hasUrlBeenReplaced.value = hasBeenReplaced
  }

  const toggleDisplayAlertMessage = (shouldDisplayAlertMessage) => {
    displayAlertMessage.value = shouldDisplayAlertMessage
  }

  const getTourSteps = (tourName) => {
    const tour = tours.value.find((tour) => tour.slug === tourName)
    if (tour && tour.tours) {
      return mapTourSteps(tour.tours)
    }
    return []
  }

  const mapTourSteps = (steps) => {
    return steps.map((step) => {
      const mappedStep = {
        popover: {
          title: step.title,
          description: step.content.replace(/\n/g, '')
        }
      }

      if (step.targetElement) {
        mappedStep.element = step.targetElement
      }

      if (step.side) {
        mappedStep.popover.side = step.side.toLowerCase()
      }

      if (step.alignment) {
        mappedStep.popover.align = step.alignment.toLowerCase()
      }

      return mappedStep
    })
  }

  const updateIsTourRunning = (isRunning) => {
    isTourRunning.value = isRunning
  }

  const addToTourQueue = (tourName, triggerOnComplete) => {
    tourQueue.value.push({ tourName, triggerOnComplete })
  }

  return {
    isLoading,
    hasUrlBeenReplaced,
    isDeveloperModeFeatures,
    shouldUseDummyData,
    apiSiteSettings,
    displayAlertMessage,
    displayCurrentSeasonBlock,
    currentSeason,
    tours,
    tourQueue,
    isTourRunning,
    toggleLoading,
    getSiteSettings,
    toggleHasUrlBeenReplaced,
    toggleDisplayAlertMessage,
    getTourSteps,
    updateIsTourRunning,
    addToTourQueue
  }
})
